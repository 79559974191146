<template>
  <div class="pageContent">
    <!-- <noNavHeader title="卖家中心" /> -->
    <div class="matching">
      <el-row :gutter="15">
        <el-col>
          <el-col :span="24">
            <div class="matchingLeft">
              <!-- <div>
                <p class="matchingLeftTitle">挂摘牌</p>
              </div>
              <router-link to="/sellerCenter/baojia">
                <p class="matchingLeftItem">发布资源</p>
              </router-link>
              <router-link to="/sellerCenter/order">
                <p class="matchingLeftItem">我的报价</p>
              </router-link>
              <router-link to="/sellerCenter/resource">
                <p class="matchingLeftItem">我的资源单</p>
              </router-link>
              <router-link to="/sellerCenter/dealOrder">
                <p class="matchingLeftItem">我的成交</p>
              </router-link>
              <router-link to="/sellerCenter/addProduct">
                <p class="matchingLeftItem">发布商品</p>
              </router-link>
              <div>
                <p class="matchingLeftTitle">产品预售</p>
              </div>
              <router-link to="/sellerCenter/productionAudit">
                <p class="matchingLeftItem">商品审核</p>
              </router-link>
              <router-link to="/sellerCenter/addResourceOrder">
                <p class="matchingLeftItem">发布产能资源</p>
              </router-link>
              <router-link to="/sellerCenter/sellOrderList">
                <p class="matchingLeftItem">产品预售订单</p>
              </router-link>
              <router-link to="/sellerCenter/shopManage">
                <p class="matchingLeftItem">店铺管理</p>
              </router-link>
              </div>-->
              <!-- <div class="mainTitle">
                <p>卖家中心</p>
              </div>
              <div>
                <p class="matchingLeftTitle">销售委托</p>
              </div>
              <router-link to="/sellerCenter/resource/holdSaleApply">
                <p class="matchingLeftItem">预售持仓申请</p>
              </router-link>
              <router-link to="/sellerCenter/resource/warehousingApply">
                <p class="matchingLeftItem">
                  现货入库操作
                  <span class="elTag" v-if="totalAppaly">{{ totalAppaly }}</span>
                </p>
              </router-link>
              <router-link to="/sellerCenter/resource/spotResource">
                <p class="matchingLeftItem">现货销售委托</p>
              </router-link>
              <router-link to="/sellerCenter/resource/spotResourceOrder">
                <p class="matchingLeftItem">
                  <span>现货销售订单</span>
                  <span class="elTag" v-if="spotResTotal">{{ spotResTotal }}</span>
                </p>
              </router-link>
              <router-link to="/sellerCenter/resource/biddingResource">
                <p class="matchingLeftItem">竞价销售委托</p>
              </router-link>
              <router-link to="/sellerCenter/resource/biddingResourceOrder">
                <p class="matchingLeftItem">
                  竞价销售订单
                  <span class="elTag" v-if="biddingResTotal">{{ biddingResTotal }}</span>
                </p>
              </router-link>
              <router-link to="/sellerCenter/resource/preSellResource">
                <p class="matchingLeftItem">预售委托</p>
              </router-link>
              <router-link to="/sellerCenter/resource/preSellResourceOrder">
                <p class="matchingLeftItem fourTextItem">
                  预售订单
                  <span class="elTag" v-if="preSellTotal">{{ preSellTotal }}</span>
                </p>
              </router-link>
              <router-link to="/sellerCenter/resource/basisTransferRegistration" v-if="isShowBaise">
                <p class="matchingLeftItem">基差转让挂单</p>
              </router-link>
              <router-link to="/sellerCenter/resource/basisList" v-if="isShowBaise">
                <p class="matchingLeftItem fourTextItem">基差挂单</p>
              </router-link> -->
              <!-- <router-link to="/sellerCenter/shopManage">
                <p class="matchingLeftItem">店铺管理</p>
              </router-link> -->
              <!-- <router-link to="/sellerCenter/resource/sellInfoResource">
                <p class="matchingLeftItem">销售信息发布</p>
              </router-link>
              <div>
                <p class="matchingLeftTitle">卖出订单</p>
              </div>
              <router-link to="/sellerCenter/sales/basisOrder" v-if="isShowBaise">
                <p class="matchingLeftItem fourTextItem">
                  基差订单
                  <span class="elTag" v-if="basisTotal">{{ basisTotal }}</span>
                </p>
              </router-link>
              <router-link to="/sellerCenter/sales/canRepOrder"  v-if="isShowBaise">
                <p class="matchingLeftItem treeTextItem">
                  可回购订单
                  <span class="elTag" v-if="canbackTotal">{{ canbackTotal }}</span>
                </p>
              </router-link>
              <router-link to="/sellerCenter/sales/basisRepoOrder" v-if="isShowBaise">
                <p class="matchingLeftItem">
                  基差回购订单
                  <span class="elTag" v-if="basisBackTotal">{{ basisBackTotal }}</span>
                </p>
              </router-link>
              <router-link to="/sellerCenter/sales/spotOrder">
                <p class="matchingLeftItem fourTextItem">
                  现货订单
                  <span class="elTag" v-if="spotTotal">{{ spotTotal }}</span>
                </p>
              </router-link>
              <router-link to="/sellerCenter/sales/biddingOrder">
                <p class="matchingLeftItem fourTextItem">
                  竞价订单
                  <span class="elTag" v-if="biddingTotal">{{ biddingTotal }}</span>
                </p>
              </router-link> -->
              <!-- <router-link to="/sellerCenter/resource/qualityInspectionReport">
                <p class="matchingLeftItem">我的质检报告</p>
              </router-link>-->
              <!-- <div>
                <p class="matchingLeftTitle" @click="changeDirect">
                  销售管理
                  <span class="downArrow" v-if="!isDown">
                    <i class="el-icon-arrow-down"></i>
                  </span>
                  <span class="downArrow" v-if="isDown">
                    <i class="el-icon-arrow-up"></i>
                  </span>
                </p>
              </div>
              <router-link to="/sellerCenter/sales/contract" v-if="isDown">
                <p class="matchingLeftItem">我的合同</p>
              </router-link>
              <router-link to="/sellerCenter/sales/registeredBidding" v-if="isDown">
                <p class="matchingLeftItem">已报名竞价</p>
              </router-link> -->
              <!-- <router-link to="/sellerCenter/sales/winningBidding">
                <p class="matchingLeftItem">中标竞价确认</p>
              </router-link>-->
              <!-- <div>
                <p class="matchingLeftTitle" @click="changeRecouse">
                  资源管理
                  <span class="downArrow" v-if="!isResoureceM">
                    <i class="el-icon-arrow-down"></i>
                  </span>
                  <span class="downArrow" v-if="isResoureceM">
                    <i class="el-icon-arrow-up"></i>
                  </span>
                </p>
              </div>
              <router-link to="/sellerCenter/sales/myPosition" v-if="isResoureceM">
                <p class="matchingLeftItem">我的持仓</p>
              </router-link> -->
              <!-- <router-link to="/sellerCenter/sales/warehouseOrder">
                <p class="matchingLeftItem">我的仓单</p>
              </router-link>-->
              <!-- <router-link
                to="/sellerCenter/sales/myPledge"
                v-if="isResoureceM&&isShowFinanceOrPledge"
              >
                <p class="matchingLeftItem">我的质押单</p>
              </router-link>
              <router-link to="/sellerCenter/sales/depositOrder" v-if="isResoureceM">
                <p class="matchingLeftItem">我的存单</p>
              </router-link>
              <router-link to="/sellerCenter/sales/qualityInspectionReport" v-if="isResoureceM">
                <p class="matchingLeftItem">持仓质检报告</p>
              </router-link>
              <router-link to="/sellerCenter/sales/depositReport" v-if="isResoureceM">
                <p class="matchingLeftItem">存单质检报告</p>
              </router-link>
              <div v-if="isShowFinanceOrPledge">
                <p class="matchingLeftTitle" @click="changeFinance">
                  融资管理
                  <span class="downArrow" v-if="!isFinance">
                    <i class="el-icon-arrow-down"></i>
                  </span>
                  <span class="downArrow" v-if="isFinance">
                    <i class="el-icon-arrow-up"></i>
                  </span>
                </p>
              </div>
              <router-link to="/sellerCenter/sales/myFinancing" v-if="isShowFinanceOrPledge">
                <p class="matchingLeftItem" v-if="isFinance">仓单融资</p>
              </router-link>
              <router-link to="/sellerCenter/sales/myOrderFinancing" v-if="isShowFinanceOrPledge">
                <p class="matchingLeftItem" v-if="isFinance">订单融资</p>
              </router-link>
              <div>
                <p class="matchingLeftTitle" @click="changeBill">
                  交收管理
                  <span class="downArrow" v-if="!isBill">
                    <i class="el-icon-arrow-down"></i>
                  </span>
                  <span class="downArrow" v-if="isBill">
                    <i class="el-icon-arrow-up"></i>
                  </span>
                </p>
              </div>
              <router-link to="/sellerCenter/logistics/selfBill" v-if="isBill">
                <p class="matchingLeftItem">自提查询</p>
              </router-link>
              <router-link to="/sellerCenter/logistics/logisticsBill" v-if="isBill">
                <p class="matchingLeftItem">物流查询</p>
              </router-link> -->
            </div>
          </el-col>
        </el-col>
        <el-col>
          <div class="matchingRight">
            <router-view />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import noNavHeader from "../base/header/noNavHeader";
export default {
  components: {
    noNavHeader,
  },
  data() {
    return {
      isDown: false,
      isResoureceM: false,
      spotTotal: 0,
      biddingTotal: 0,
      preSellTotal: 0,
      spotResTotal: 0,
      biddingResTotal: 0,
      basisBackTotal: 0,
      basisTotal: 0,
      canbackTotal: 0,
      totalAppaly: 0,
      isBill: false,
      isFinance: false,
      isShowFinanceOrPledge: window.g.isShowFinanceOrPledge,
      isShowBaise: window.g.isShowBaise
    };
  },
  mounted() {
    this.getSpotTotal();
    this.getBiddingTotal();
    this.getPreSellTotal();
    this.getSpotResTotal();
    this.getBiddingResTotal();
    this.getAppplyHold();
    if(this.isShowBaise){
      this.getBasisBackTotal();
      this.getBasisTotal();
      this.getCanbackTotal();
    }
  },
  methods: {
    changeDirect() {
      if (this.isDown) {
        this.isDown = false;
      } else {
        this.isDown = true;
      }
    },
    changeRecouse() {
      if (this.isResoureceM) {
        this.isResoureceM = false;
      } else {
        this.isResoureceM = true;
      }
    },
    changeBill() {
      if (this.isBill) {
        this.isBill = false;
      } else {
        this.isBill = true;
      }
    },
    changeFinance() {
      if (this.isFinance) {
        this.isFinance = false;
      } else {
        this.isFinance = true;
      }
    },
    getAppplyHold() {
      protocolFwd.param_queryFirmAppplyHold.param.applyHoldId = null;
      protocolFwd.param_queryFirmAppplyHold.param.page = 0;
      protocolFwd.param_queryFirmAppplyHold.param.size = 1;
      protocolFwd.param_queryFirmAppplyHold.param.sort = [
        { property: "createTime", direction: "DESC" },
      ];
      protocolFwd.param_queryFirmAppplyHold.param.applyHoldStatus = null;
      protocolFwd.param_queryFirmAppplyHold.param.auditStatus = ["F"];
      protocolFwd.param_queryFirmAppplyHold.param.type = null;
      protocolFwd.param_queryFirmAppplyHold.param.commodityId = null;
      protocolFwd.param_queryFirmAppplyHold.param.specificationsName = null;
      protocolFwd.param_queryFirmAppplyHold.param.inspectionStatus = null;
      http.postFront(protocolFwd.param_queryFirmAppplyHold).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.totalElements) {
            this.totalAppaly = value.totalElements;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 卖出订单>现货订单
    getSpotTotal() {
      protocolFwd.param_queryFirmOrder.param = {
        page: 0,
        size: 10,
        pickFirmId: sessionStorage.getItem("userFirmId"),
        orderId: null,
        startTime: null,
        endTime: null,
        tradeStatus: ["Z", "ZZ"],
        arrivalStatus: ["F", "T", "B", "ZZ"],
        payStatus: ["W", "ZZ", "F", "L"],
        openTicketStatus: ["W", "O", "ZZ"],
        pickResourcesStatus: ["W", "T"],
          defaultBuyStatus:['N','W'],
        defaultSellStatus:['N','W'],
        sourceId: "W",
        hangResourceType: ["Q"],
      };
      http.postFront(protocolFwd.param_queryFirmOrder).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.totalElements) {
            this.spotTotal = value.totalElements;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 卖出订单>竞价订单
    getBiddingTotal() {
      protocolFwd.param_queryFirmOrder.param = {
        page: 0,
        size: 10,
        pickFirmId: sessionStorage.getItem("userFirmId"),
        orderId: null,
        startTime: null,
        endTime: null,
        tradeStatus: ["Z", "ZZ"],
        arrivalStatus: ["F", "T", "B", "ZZ"],
        payStatus: ["W", "ZZ", "F", "L"],
        openTicketStatus: ["W", "O", "ZZ"],
        pickResourcesStatus: ["W", "T"],
          defaultBuyStatus:['N','W'],
        defaultSellStatus:['N','W'],
        sourceId: "J",
        hangResourceType: ["Q"],
      };
      http.postFront(protocolFwd.param_queryFirmOrder).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.totalElements) {
            this.biddingTotal = value.totalElements;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 预售订单
    getPreSellTotal() {
      protocolFwd.param_queryFirmOrder.param = {
        page: 0,
        size: 10,
        hangFirmId: sessionStorage.getItem("userFirmId"),
        orderId: null,
        startTime: null,
        endTime: null,
        tradeStatus: ["Z", "ZZ"],
        arrivalStatus: ["F", "T", "B", "ZZ"],
        payStatus: ["W", "ZZ", "F", "L"],
        openTicketStatus: ["W", "O", "ZZ"],
        pickResourcesStatus: ["W", "T"],
          defaultBuyStatus:['N','W'],
        defaultSellStatus:['N','W'],
        sourceId: "C",
        hangResourceType: ["B"],
      };
      http.postFront(protocolFwd.param_queryFirmOrder).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.totalElements) {
            this.preSellTotal = value.totalElements;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 现货订单
    getSpotResTotal() {
      protocolFwd.param_queryFirmOrder.param = {
        page: 0,
        size: 10,
        hangFirmId: sessionStorage.getItem("userFirmId"),
        orderId: null,
        startTime: null,
        endTime: null,
        tradeStatus: ["Z", "ZZ"],
        arrivalStatus: ["F", "T", "B", "ZZ"],
        payStatus: ["W", "ZZ", "F", "L"],
        openTicketStatus: ["W", "O", "ZZ"],
        pickResourcesStatus: ["W", "T"],
        sourceId: "W",
        defaultBuyStatus:['N','W'],
        defaultSellStatus:['N','W'],
        hangResourceType: ["X"],
      };
      http.postFront(protocolFwd.param_queryFirmOrder).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.totalElements) {
            this.spotResTotal = value.totalElements;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 竞价订单
    getBiddingResTotal() {
      protocolFwd.param_queryFirmOrder.param = {
        page: 0,
        size: 10,
        hangFirmId: sessionStorage.getItem("userFirmId"),
        orderId: null,
        startTime: null,
        endTime: null,
        tradeStatus: ["Z", "ZZ"],
        arrivalStatus: ["F", "T", "B", "ZZ"],
        payStatus: ["W", "ZZ", "F", "L"],
        openTicketStatus: ["W", "O", "ZZ"],
        pickResourcesStatus: ["W", "T"],
        hangResourceType: ["X", "B"],
          defaultBuyStatus:['N','W'],
        defaultSellStatus:['N','W'],
        sourceId: "J",
      };
      http.postFront(protocolFwd.param_queryFirmOrder).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.totalElements) {
            this.biddingResTotal = value.totalElements;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 基差订单
    getBasisTotal() {
      let params = {
        page: 0,
        size: 10,
        hangFirmId: sessionStorage.getItem("userFirmId"),
        pickResourcesStatus: ["W"],
      };
      protocolFwd.param_queryBasisQueryOrderLd.param = params;
      http
        .postFront(protocolFwd.param_queryBasisQueryOrderLd)
        .then((response) => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if (value.totalElements) {
              this.basisTotal = value.totalElements;
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    // 可回购订单
    getCanbackTotal() {
      let params = {
        page: 0,
        size: 10,
        pickResourcesStatus: ["T"],
      };
      protocolFwd.param_queryBasisMemberQueryOrderLd.param = params;
      http
        .postFront(protocolFwd.param_queryBasisMemberQueryOrderLd)
        .then((response) => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if (value.totalElements) {
              this.canbackTotal = value.totalElements;
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    // 基差回购订单
    getBasisBackTotal() {
      let params = {
        page: 0,
        size: 10,
        bsFlag: "M",
        repoStatus: ["W"],
        repoType: "F",
      };
      protocolFwd.param_queryBasisQueryRepoOrderLd.param = params;
      http
        .postFront(protocolFwd.param_queryBasisQueryRepoOrderLd)
        .then((response) => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if (value.totalElements) {
              this.basisBackTotal = value.totalElements;
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.matching {
  width: 80%;
  min-width: 1300px;
  min-height: 1000px;
  margin: 0 auto;
  // background-color: #ffffff;
  // border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.matching .matchingLeft {
  line-height: 40px;
  background-color: #ffffff;
  // border: 1px solid #dcdcdc;
}
.matching .matchingLeft .mainTitle {
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background-color: $commonUserTitleColor;
}
.matching .matchingLeft .mainTitle p {
  font-size: 16px;
}
.matching .matchingLeft .matchingLeftTitle {
  padding-left: 30px;
  font-size: 16px;
  font-weight: 700;
  position: relative;
}
.matching .matchingLeft .matchingLeftItem {
  padding-left: 30px;
  font-size: 14px;
  color: #333333;
  position: relative;
}
.matching .matchingLeft .matchingLeftTitle .downArrow {
  left: 100px;
  top: 0px;
  position: absolute;
}
.matching .matchingRight {
  padding: 15px 0;
  min-height: 1000px;
  background-color: #ffffff;
  // border: 1px solid #dcdcdc;
}
.router-link-active p.matchingLeftItem {
  color: $commonThemeColor !important;
}
.matching .matchingLeft .matchingLeftItem .elTag {
  position: absolute;
  left: 107px;
  top: 0px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  color: red;
  border: 1px solid red;
  line-height: 18px;
  text-align: center;
  -webkit-transform: scale(0.75);
}
.matching .matchingLeft .fourTextItem .elTag {
  left: 80px;
}
.matching .matchingLeft .treeTextItem .elTag {
  left: 90px;
}
</style>
